import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import CtaButton from '../reusable/CtaButton';
import { colors } from '../../constants/styles';
import { device } from '../../constants/mediaQueries';

const BannerWrap = styled.div`
    background: ${colors.darkPurple} url(${props => props.background}) center center no-repeat;
    background-size: cover;
    padding-bottom: 0;
    text-align: center;
    padding-top: 3rem;

    h1 {
        ext-align: center;
        font-weight: 300;
        font-size: 1.5rem;
        color: ${colors.white};
        text-transform: none;
        letter-spacing: 0;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }

    h2 {
        color: ${colors.lightGreen};
        font-size: 1.25rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2.3rem;
        letter-spacing: 0.04em;
        margin-top: 1rem;
    }

    img {
        display: none;
    }

    @media ${device.deviceXXS} {
        h1 {
            font-size: 2rem;
        }

        h2 {
            font-size: 1.65rem;
            margin-bottom: 3.3rem;
        }
    }

    @media ${device.deviceS} {
        h2 {
            margin-bottom: 1.5rem;
        }

        img {
            display: block;
        }
    }
`;

const HomeBanner = () => {
    const {graph, background} = useStaticQuery(graphql`
        {
            graph: allFile (filter: {name: {eq: "graph"}, extension: {eq: "png"}} ) {
                edges {
                    node {
                      publicURL
                    }
                  }
            }
            background: allFile (filter: {name: {eq: "header"}, extension: {eq: "jpg"}} ) {
                edges {
                    node {
                      publicURL
                    }
                  }
            }
        }
    `);
    return (
        <BannerWrap background={background.edges[0].node.publicURL}>
            <div className="container">
                <h1>3,5 billion online customers searching for your products</h1>
                <h2>Launch your own ROI-oriented advertising strategy</h2>
                <img src={graph.edges[0].node.publicURL} alt="Graph" />
                <CtaButton text="Get Profit Formula" arrowType="normal" arrowText="10 secret ingredients in 60 seconds" action={{url: '/steps/data-collection/'}} />
            </div>
        </BannerWrap>
    );
}

export default HomeBanner;