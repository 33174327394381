import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeBanner from '../components/home/HomeBanner';
import Funnel from '../components/reusable/Funnel';
import HomeReports from '../components/home/HomeReports';
import Slider from '../components/home/slider';
import HomeGeography from '../components/home/HomeGeography';
import Phones from '../components/reusable/Phones';
import Advantages from '../components/reusable/Advantages';
import ContactGeoWrap from '../components/reusable/ContactGeoWrap';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HomeBanner />
    <Funnel />
    <HomeReports />
    <Slider />
    <Advantages />
    <ContactGeoWrap>
      <HomeGeography />
      <Phones />
    </ContactGeoWrap>
  </Layout>
)

export default IndexPage;

/// TODO: **tell about problem with small images in guides
