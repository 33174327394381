import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/styles';

const Dot = styled.span`
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    margin-right: 0.3rem;
    cursor: pointer;
    border-radius: 50%;
    background: ${props => props.active ? colors.darkGrey : colors.lightGrey};
`;

const DotsWrap = styled.div`
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Dots = ({ slides, activeIndex, handleClick }) => {
    return (
        <DotsWrap>
            {slides.map((slide, i) => {
                return (
                    <Dot key={i} active={i === activeIndex} onClick={() => {handleClick(i)}} />
                );
            })}
        </DotsWrap>
    );
}



export default Dots;