import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Swipeable } from 'react-swipeable';
import SliderContent from './SliderContent';
import Slide from './Slide';
import { useStaticQuery, graphql } from 'gatsby';
import Dots from './Dots';
import CtaButton from '../../reusable/CtaButton';

const SliderWrap = styled.section`
    padding-top: 2rem;

    h1 {
        p {
            margin: 0;
        }
    }

    .slider {
        position: relative;
        height: auto;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
    }
`;

const Slider = () => {

    const targetRef = useRef();
    const [width, setWidth] = useState(0);
    const [translate, setTranslate] = useState(0);
    const transition = 0.45;
    const [activeIndex, setActiveIndex] = useState(0);



    /// slider navigation: dots
    const handleDotClick = (index) => {
        if (activeIndex !== index){
            setTranslate(index * width);
            setActiveIndex(index);
        }
    }

    /// slider navigation: swipe
    const swipeHandler = (direction) => {
        let newIndex;
        if (direction === 'left' && activeIndex !== slides.edges.length - 1){
            newIndex = activeIndex + 1;
        } else if (direction === 'right' && activeIndex !== 0){
            newIndex = activeIndex - 1;
        } else {
            return;
        }
        setTranslate(newIndex * width);
        setActiveIndex(newIndex);
    }

    /// set slide width same as slider block width
    useLayoutEffect(() => {
        if(targetRef.current){
            setWidth(targetRef.current.offsetWidth);
        }
    }, []);

    /// handle window resize
    useLayoutEffect(() => {
        if(targetRef.current){
            const updateSize = () => {
                setWidth(targetRef.current.offsetWidth);
                setTranslate(0);
                setActiveIndex(0);
            }
            window.addEventListener('resize', updateSize);
            return () => window.removeEventListener('resize', updateSize);
        }
    }, []);

    

    const { slides } = useStaticQuery(graphql`
        {
            slides: allContentfulSlides {
                edges {
                  node {
                    title
                    orderNumber
                    goals
                    approach
                    results
                    image {
                      file {
                        url
                      }
                    }
                  }
                }
              }
        }
    `);

    

    return (
        <SliderWrap>
            <div className="container">
                <h1 className="huge">
                    The result is 331% growth in Convertions and 0% growth in Cost. 
                    <p><strong>Are you ready for such results?</strong></p>
                </h1>
                <div className="slider" ref={targetRef}>
                    <Dots slides={slides.edges} activeIndex={activeIndex} handleClick={handleDotClick} />
                    <Swipeable
                        trackMouse
                        preventDefaultTouchmoveEvent
                        onSwipedLeft={() => swipeHandler('left')}
                        onSwipedRight={() => swipeHandler('right')}
                        >
                        <SliderContent 
                            translate={translate}
                            transition={transition}
                            width={width} 
                        >
                            {slides.edges.map(({ node }) => {
                                return (
                                    <Slide key={node.title} content={node} />
                                );
                            })}
                        </SliderContent>
                    </Swipeable>
                </div>
                <CtaButton arrowType="round" arrowText="Multiply the profit for 5 days" text="Get an ROI-oriented strategy" action={{modal: true, source: 'Slider'}} />
            </div>
        </SliderWrap>
        
    );
}

export default Slider;