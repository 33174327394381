import React from 'react';
import CtaButton from '../reusable/CtaButton';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { blueGradient, colors } from '../../constants/styles';
import { device } from '../../constants/mediaQueries';

const ReportsWrap = styled.section`
    ${blueGradient}
    padding-top: 1rem;

    h1.huge {
        color: ${colors.white};
    }

    .container {
        .content {
            margin-bottom: 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            img:nth-child(2) {
                margin: 2rem;
                transform: rotate(90deg);
            }

            div {
                border: 6px solid ${colors.darkBlue};
                border-radius: 10px;
                box-shadow: inset 0px 1px 9px 0px ${colors.lightShadowed};
                padding: 1.5rem;        
            }

            @media ${device.deviceS} {
                flex-direction: row;
                justify-content: space-between;

                > img:nth-child(2) {
                    transform: none;
                }

                > img:nth-child(1) {
                    max-width: 30%;
                }

                div {
                    img {
                        width: 100%;
                    }
                    flex-grow: 0;
                    min-width: 40%;
                }
            }
        }
    }
`;

const HomeReports = () => {
    const { cloud, arrow, report } = useStaticQuery(graphql`
    {
        cloud: allFile(filter: {name: {eq: "cloud"}, extension: {eq: "png"}}) {
            edges {
                node {
                    publicURL
                }
            }
        }
        arrow: allFile(filter: {name: {eq: "arrow-report"}, extension: {eq: "png"}}) {
            edges {
                node {
                    publicURL
                }
            }
        }
        report: allFile(filter: {name: {eq: "report"}, extension: {eq: "jpg"}}) {
            edges {
                node {
                    publicURL
                }
            }
        }
    }
    `);
    return (
        <ReportsWrap>
            <div className="container">
                <h1 className="huge">You set goals and <strong>control all actions</strong></h1>
                <div className="content">
                    <img src={cloud.edges[0].node.publicURL} alt="Cloud" />
                    <img src={arrow.edges[0].node.publicURL} alt="Arrow" />
                    <div>
                        <img src={report.edges[0].node.publicURL} alt="Report" />
                    </div>
                </div>
                <CtaButton text="Get a detailed audit" arrowType="normal" arrowText="Check out KPI advertising companies" action={{modal: true, source: 'Home Reports'}} />
            </div>
        </ReportsWrap>
    );
}


export default HomeReports;