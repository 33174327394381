import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants/styles';
import { device } from '../../../constants/mediaQueries';

const SlideWrap = styled.div`
    flex-shrink: 0;
    height: auto;
    width: 100%;

    img {
        display: block;
    }
        @media ${device.deviceM}{
            
            display: flex;
            justify-content: space-between;

            img {
                height: auto;
                align-self:flex-start;
            }
        }
    
        h3 {
            font-weight: 300;
            color: ${colors.orange2};
        }

        

        > div {
            padding-right: 1.5rem;
        }

        h4 {
            font-weight: bold;
            font-size: 1.1rem;
            color: ${colors.darkPurple};
            text-transform: uppercase;
        }

    li {
        margin-bottom: 0;
    } 
`;

const Slide = ({content}) => {
    return (
        <SlideWrap key={content.title}>
            <div>
                <h3>{content.title}</h3>
                <h4>Goals</h4>
                <ul>
                    {content.goals.map((text) => {
                        return (
                            <li key={text}>{text}</li>
                        );
                    })}
                </ul>
                <h4>Approach</h4>
                <ul>
                    {content.approach.map((text) => {
                        return (
                            <li key={text}>{text}</li>
                        );
                    })}
                </ul>
                <h4>Results</h4>
                <ul>
                    {content.results.map((text) => {
                        return (
                            <li key={text}>{text}</li>
                        );
                    })}
                </ul>
            </div>
            <img src={content.image.file.url} alt={content.title} />
        </SlideWrap>
    );
}

export default Slide;